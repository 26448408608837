import React from "react";

import { useRouter } from "next/router";

import { Error } from "@/components";
import content from "@/content";

const testId = content.pages.notFound.testId;

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <Error
      errorButtonText={content.pages.notFound.errorButtonText}
      errorText={content.pages.notFound.errorText}
      onReloadAction={() => router.push(content.pages.notFound.onReloadActionPageRouting)}
      testId={testId}
    />
  );
};

export default NotFoundPage;
